import {
  AppointmentTypeSettingDTO,
} from 'dtos/appointmentTypeSettingDTO';
import compact from 'lodash/compact';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import zipObject from 'lodash/zipObject';
import {
  colorClasses,
} from 'pages/Dashboard/pages/Encounters/hooks';
import {
  AppointmentTypeSettings,
} from 'views/EMR/Settings/Appointments/List';
import {
  isEmptyString,
} from 'utils/misc';

export function assignAppointmentTypeColors(
  appointmentTypes: string[],
): Record<string, string> {
  return {
    ...zipObject(
      appointmentTypes,
      appointmentTypes.map((type, index) => colorClasses[index % appointmentTypes.length]),
    ),
    'Time Blocked': '!bg-rose-300',
  };
}

export function normalizeAppointmentTypes(
  data: AppointmentTypeSettingDTO[],
): AppointmentTypeSettings[] {
  const appointmentTypes = data?.filter((item) => item?.appointmentType !== 'Time Blocked');
  const colors = assignAppointmentTypeColors(
    compact(map(appointmentTypes, 'appointmentType')),
  );

  return appointmentTypes?.map((item) => ({
    ...item,
    color: colors[item?.appointmentType ?? ''],
  }));
}

export function getSectionLabelByName(sectionName: string = '') {
  return startCase(sectionName?.replace('Template', ''));
}

export const EMPTY_CKE_TEXT = '<p><br data-cke-filler="true" /></p>';
export function isEmptyRichText(content: string = '') {
  return content === EMPTY_CKE_TEXT;
}

export function normalizeContent(content: string = '') {
  return isEmptyString(content)
    ? EMPTY_CKE_TEXT
    : (content ?? '').split('\n').map((paragraph) => `<p>${paragraph}</p>`).join('');
}

export function serializeContent(htmlContent: string = '') {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlContent, 'text/html');
  const value = Array.from(doc.body.children)
    .map((paragraph) => paragraph.textContent)
    .join('\n');
  return isEmptyString(value) ? null : value;
}
